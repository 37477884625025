//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {NavigationMixin} from "@/mixin/helpers/NavigationMixin"
import {CrudHelper} from "@/vold-core/functions/Crudhelper"
import {TimelineMax} from 'gsap'
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate'
import {triggerResize} from "@/plugins/helper"
import StaticText from "~/directives/StaticText";
import axios from 'axios';

export default {
    name: "Footer",
    components: {ValidationProvider, ValidationObserver},
    directives: {StaticText},
    mixins: [NavigationMixin],
    data() {
        return {
            showNewsletter: false,
            first_name: null,
            last_name: null,
            email: null,
            loading: false,
        }
    },
    computed: {
        services() {
            return this.getNavigationByID('service')
        },
        information() {
            return this.getNavigationByID('information')
        },
        bottom() {
            return this.getNavigationByID('bottom')
        },
        contact() {
            return this.getNavigationByID('contact')
        },
        contactAddress() {
            return this.getStaticText('footer contact address')
        },
        newsletter() {
            return this.escapedStaticText('footer - newsletter title')
        },
        phone() {
            let phone = this.getStaticText('phone')

            if (phone != null) {
                return phone.replace(/<(.*?)>/g, '')
            }

            return null
        },
        fax() {
            let fax = this.getStaticText('fax')

            if (fax != null) {
                return fax.replace(/<(.*?)>/g, '')
            }

            return null
        }
    },
    watch: {
        showNewsletter() {
            setTimeout(triggerResize, 500)
        }
    },
    mounted() {
        if (window.rules == null) {
            window.rules = require('vee-validate/dist/rules')
        }

        extend('required', window.rules.required)
        extend('email', window.rules.email)
        extend('nosymbol', this.nosymbol)

    },
    methods: {
        mouseOver() {
            if (!this.loading &&
                (this.$refs.btn.classList.contains('is-invalid') || this.$refs.btn.classList.contains('is-valid'))) {
                const tl = new TimelineMax;
                tl.to(this.$refs.btn, .5, {
                    opacity: 0,
                    onComplete: () => {
                        this.$refs.btn.classList.remove('is-invalid')
                        this.$refs.btn.classList.remove('is-progress')
                        this.$refs.btn.classList.remove('is-valid')
                        this.$refs.btn.textContent = 'Subscribe'
                    }
                })

                tl.to(this.$refs.btn, .5, {
                    opacity: 1
                })
            }
        },
        async onNewsletterSubmit() {
            const tl = new TimelineMax
            this.loading = true;

            try {
                tl.to(this.$refs.btn, .5, {
                    opacity: 0,
                    onComplete: () => {
                        this.$refs.btn.classList.add('is-progress')
                        this.$refs.btn.textContent = 'Loading'
                    }
                })

                tl.to(this.$refs.btn, .5, {
                    opacity: 1
                })

                if (window.location.hostname.match(/lusticabay\.com$/) != null) {
                    await axios.post('https://prod-163.westeurope.logic.azure.com:443/workflows/c0643aeeafae4d4a84752b2074190beb/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=IanoMg2Dde8LaOA5eas_vogX7d2nZ-8vL6GiiaOBEnM', {
                        Lead: {
                            firstname: this.first_name,
                            lastname: this.last_name,
                            email: this.email
                        }
                    })

                    await CrudHelper.POST('contact-us', {
                        data: [
                            {
                                key: 'Name',
                                value: this.first_name,
                            },
                            {
                                key: 'Email',
                                value: this.email
                            }
                        ],
                        type: 'contact-us',
                        template: 'contact us lustica',
                        language: 'en',
                        autoRender: true,
                        recipients: [
                            {
                                name: 'Info@Lusticabay.com',
                                email: 'Info@Lusticabay.com'
                            }
                        ]
                    })
                } else {
                    await axios.post('https://prod-217.westeurope.logic.azure.com:443/workflows/e47b176d5efd4a769b508f7006ef9ba2/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=j4ledWzHke4D2zkiVYHNp3-ztST6UXoCIQgfJgG4RqU', {
                        Lead: {
                            firstname: this.first_name,
                            lastname: this.last_name,
                            email: this.email
                        }
                    })
                }

                tl.to(this.$refs.btn, .5, {
                    opacity: 0,
                    onComplete: () => {
                        this.$refs.btn.classList.remove('is-progress')
                        this.$refs.btn.classList.add('is-valid')
                        this.$refs.btn.textContent = 'Success'
                    }
                })

                tl.to(this.$refs.btn, .5, {
                    opacity: 1
                })


            } catch (e) {
                tl.to(this.$refs.btn, .5, {
                    opacity: 0,
                    onComplete: () => {
                        this.$refs.btn.classList.remove('is-progress')
                        this.$refs.btn.classList.add('is-invalid')
                        this.$refs.btn.textContent = 'Error'
                    }
                })

                tl.to(this.$refs.btn, .5, {
                    opacity: 1,
                    onComplete: () => {
                        this.loading = false;
                    }
                })
            }
        }
    }
}
