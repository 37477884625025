//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {CrudHelper} from "@/vold-core/functions/Crudhelper"
import {GlobalMixin} from "@/mixin/GlobalMixin"
import {PostMixin} from "@/mixin/helpers/PostMixin"

export default {
    name: "JobListComponent",
    mixins: [GlobalMixin, PostMixin],
    props: {
        title: {
            type: String,
            required: false,
            default: 'Job Openings'
        },
        not: {
            type: String,
            required: false
        }
    },
    async mounted() {
        let where = {type: 'career'}

        if (this.not != null) {
            where.id = {$ne: this.not}
        }

        let response = await CrudHelper.GET('post', {
            where,
            size: 4,
            skip: 0,
            page: 0
        });

        this.posts = response.data.results;
    }
}
