//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "../../../mixin/GlobalMixin"
import {Components} from "../Components"
import {VoldPostContentMixin} from "../../../vold-core/mixin/VoldPostContentMixin"
import Page404Component from "../../global/Page404Component/Page404Component";
import {mapState} from 'vuex';
import TweenMax from "gsap"

export default {
    mixins: [GlobalMixin, VoldPostContentMixin],

    components: {
        Page404Component,
        ...Components
    },

    props: {
        components: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            FamilyPoolVilla: null,
            dOptions: {
                licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
                onLeave: this.onLeave
            },
            navigationItem: null,
            isMob: false,
            isPhone: false,
            voldComponents: []
        }
    },
    watch: {
        voldComponents() {
            this.$nextTick(() => {
                this.$store.commit('setDetailLoaded', true);
            })
        }
    },

    computed: mapState({
        loadingCompleted: state => state.SmoothScroll.loadingCompleted
    }),

    mounted() {
        this.isMob = this.detectmob()
        this.isPhone = this.isPhoneCheck()
        this.voldComponents = [...this.getComponents]
    },
    destroyed() {
        // window.removeEventListener("resize", this.handleResize)
    },
    methods: {
        onLeave(origin, destination, direction) {

            const fpSections = document.querySelectorAll('.fp-section')


            // change color only on first section item
            if (destination && destination.isFirst) {
                this.$store.commit('setNavColorActive', true)
            } else {
                this.$store.commit('setNavColorActive', false)
            }

            // detect if user enter heroBannerDistortion
            let itemHeroBannerDistortion = destination.item.querySelector(".hero-banner-distortion")
            if (itemHeroBannerDistortion) {
                this.$store.commit('setEnterHeroBannerDistortion', true)
            } else {
                this.$store.commit('setEnterHeroBannerDistortion', false)
            }


            if (fpSections) {
                let section = fpSections[origin.index]

                if (direction === 'up') {
                    section = fpSections[destination.index]
                }

                let background = section.querySelector('.background-image')


                const translateYPos = window.innerHeight / 3

                let posY = {
                    start: 0,
                    end: translateYPos,
                }


                if (direction === 'up') {
                    posY = {
                        start: translateYPos,
                        end: 0,
                        ease: null
                    }
                }

                if (background) {
                    TweenMax.fromTo(background, .5, {
                        Y: posY.start,
                    }, {
                        y: posY.end
                    })
                }

            }

        },

        isPhoneCheck() {
            if (this.isClient) {
                return window.innerWidth < 1024
            }
            return false
        },
        handleResize() {
            this.isPhone = this.isPhoneCheck()
            this.$store.commit('setScreenWidth', window.innerWidth)
        }


    },
}
