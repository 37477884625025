//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"
import LinkEditor2 from "@/directives/LinkEditorPlugin"
import {TweenMax, Expo} from 'gsap';

export default {
    name: "MapBoxComponent",
    directives: {LinkEditor2},
    mixins: [GlobalMixin],
    data() {
        return {
            page: 0,
            resizing: false,
            resizeTimeout: null
        }
    },
    computed: {
        location() {
            return this.getDataModel.locations[this.page];
        }
    },
    watch: {
        page() {
            this.location.categories.map((cat, i) => {
                this.$refs.cat[i].textContent = cat;
            });

            this.$refs.title.textContent = this.location.title;
            this.$refs.description.textContent = this.location.description;
            this.$refs.link.textContent = this.location.link.title;
            this.$refs.link.setAttribute('href', this.renderLink(this.location.link.url));

            this.translate();
        },
        resizing(resizing) {
            if (!resizing) {
                if (window.innerWidth >= 1280) {
                    TweenMax.to('.map-container', .5, {
                        x: '0%',
                        ease: Expo.easeOut
                    });
                } else if (window.innerWidth < 1280) {
                    this.translate();
                }
            }
        }
    },
    mounted() {
        this.translate();

        window.addEventListener('resize', () => {
            this.resizing = true;
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(() => this.resizing = false, 500);
        })
    },
    methods: {
        onMarkerEnter(args, y) {
            let el = args[0];
            let done = args[1];

            let span = el.querySelector('span')
            let spanWrapper = el.querySelector('.line__text-wrap')

            TweenMax.set(span, { opacity: 0, yPercent: 100 })
            TweenMax.fromTo(el, .5, {
                height: 0,
                bottom: y < 10 ? 'unset' : 50,
                top: y < 10 ? '100%' : 'unset',
            }, {
                height: 50,
                ease: Expo.easeOut
            });

            if (y < 10) {
                TweenMax.set(spanWrapper, {
                    top: '50%'
                })

                TweenMax.set(span, {
                    yPercent: -100
                })
            }


            TweenMax.to(span, .5, {
                opacity: 1,
                top: y < 5 ? '50%' : 'unset',
                yPercent: 0,
                ease: Expo.easeOut,
                onComplete: done
            })
        },
        translate() {
            const map = document.querySelector('.map-container');
            let translate = -this.location.x + ((window.innerWidth - 15) / map.clientWidth * 100 / 2);

            if (translate > 0) {
                translate = 0;
            }

            if ((window.innerWidth * (translate / -100) > 230)) {
                translate = (1 - window.innerWidth / map.clientWidth) * -100;
            }

            if (window.innerWidth < 1280) {
                TweenMax.to('.map-container', .5, {
                    x: `${translate}%`,
                    ease: Expo.easeOut
                });
            }
        },
        onMapClick(e) {
            if (!this.isCurrentUserCanEdit) return;

            let map = e.target

            const width = map.clientWidth
            const height = map.clientHeight

            const rect = e.target.getBoundingClientRect()
            const x = e.clientX - rect.left
            const y = e.clientY - rect.top

            this.location.x = ((x - 15) / width) * 100
            this.location.y = ((y - 15) / height) * 100
        },
        next() {
            if (this.page < this.getDataModel.locations.length - 1) {
                this.page++;
            }
        },
        previous() {
            if (this.page > 0) this.page--;
        }
    }
}
