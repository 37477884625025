import {TweenMax} from "gsap"

const HasDarkBox = {
    data() {
        return {
            dark: false
        }
    },
    computed: {
        mode() {
            return this.dark ? 'dark' : 'light'
        },
    },
    watch: {
        dark(val) {
            this.getDataModel.darkBoxText = val
        },
    },
    methods: {
        toggleBg() {
            this.setTextBoxMode(!this.dark)
        },
        setTextBoxMode(dark) {
            let el = this.$refs.boxText
            TweenMax.to(el, .5, {
                backgroundColor: dark ? '#001e31' : '#fff',
                onComplete: () => {
                    if (this.isCurrentUserCanEdit) {
                        this.dark = dark
                    }
                }
            })

            TweenMax.to(el.querySelectorAll('h2'), .5, {
                color: dark ? '#fff' : '#001e31'
            })

            TweenMax.to(el.querySelectorAll('p'), .5, {
                color: dark ? '#fff' : '#001c2d'
            })

            const btn = el.querySelector('.btn')

            if (btn != null) {
                TweenMax.to(btn, .5, {
                    className: dark ? '+=white' : '-=white'
                })
            }


            if (this.isCurrentUserCanEdit) {
                TweenMax.to(el.querySelector('.toggler'), .5, {
                    backgroundColor: dark ? '#fff' : '#001e31',
                    boxShadow: `0 0 5px 0 rgba(${dark ? '255, 255, 255' : '0, 0, 0'}, 0.5)`
                })
            }
        },
    },
    mounted() {
        this.dark = this.getDataModel.darkBoxText
        this.setTextBoxMode(this.dark)
    }
}

export default HasDarkBox
