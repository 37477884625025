var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"section-config",rawName:"v-section-config",value:({ options: _vm.getOptions,module_name:this.dataName }),expression:"{ options: getOptions,module_name:this.dataName }"}],staticClass:"section marina-form",attrs:{"data-scroll-section":""}},[_c('validation-observer',{staticClass:"q-container",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h2',{directives:[{name:"vold-text-editor",rawName:"v-vold-text-editor",value:({ state: function (updated) { return _vm.getDataModel.title = updated; } }),expression:"{ state: updated => getDataModel.title = updated }"}],staticClass:"text-center"},[_vm._v("\n            "+_vm._s(_vm.getDataModel.title)+"\n        ")]),_vm._v(" "),_c('form',{staticClass:"form",attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('validation-provider',{staticClass:"form-group col-6",attrs:{"rules":"required|nosymbol","tag":"div","name":_vm.escapedStaticText('form - first name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{directives:[{name:"static-text",rawName:"v-static-text",value:('form - first name'),expression:"'form - first name'"}],attrs:{"for":"first_name"}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"},{name:"static-text",rawName:"v-static-text",value:('form - first name'),expression:"'form - first name'"}],staticClass:"form-control",attrs:{"id":"first_name","placeholder":"","type":"text","required":""},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{staticClass:"form-group col-6",attrs:{"rules":"required|nosymbol","tag":"div","name":_vm.escapedStaticText('form - last name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{directives:[{name:"static-text",rawName:"v-static-text",value:('form - last name'),expression:"'form - last name'"}],attrs:{"for":"last_name"}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"},{name:"static-text",rawName:"v-static-text",value:('form - last name'),expression:"'form - last name'"}],staticClass:"form-control",attrs:{"id":"last_name","placeholder":"","type":"text","required":""},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('validation-provider',{staticClass:"form-group col-4",attrs:{"rules":"required|email","tag":"div","name":_vm.escapedStaticText('form - email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{directives:[{name:"static-text",rawName:"v-static-text",value:('form - email'),expression:"'form - email'"}],attrs:{"for":"email"}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"},{name:"static-text",rawName:"v-static-text",value:('form - email'),expression:"'form - email'"}],staticClass:"form-control",attrs:{"id":"email","placeholder":"","type":"email","required":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form-group col-4"},[_c('label',{directives:[{name:"static-text",rawName:"v-static-text",value:('form - phone'),expression:"'form - phone'"}],attrs:{"for":"phone"}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"},{name:"static-text",rawName:"v-static-text",value:('form - phone'),expression:"'form - phone'"}],staticClass:"form-control",attrs:{"id":"phone","placeholder":"","type":"text"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}})]),_vm._v(" "),_c('validation-provider',{staticClass:"form-group col-4",attrs:{"rules":"required","tag":"div","name":_vm.escapedStaticText('form - country')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{directives:[{name:"static-text",rawName:"v-static-text",value:('form - country'),expression:"'form - country'"}],attrs:{"for":"country"}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.country),expression:"form.country"},{name:"static-text",rawName:"v-static-text",value:('form - country'),expression:"'form - country'"}],staticClass:"form-control",attrs:{"id":"country","placeholder":"","type":"text","required":""},domProps:{"value":(_vm.form.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "country", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('validation-provider',{staticClass:"form-group col-4",attrs:{"rules":"required","tag":"div","name":_vm.escapedStaticText('form - message')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{directives:[{name:"static-text",rawName:"v-static-text",value:('form - message'),expression:"'form - message'"}],attrs:{"for":"message"}}),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"},{name:"static-text",rawName:"v-static-text",value:('form - message'),expression:"'form - message'"}],staticClass:"form-control",attrs:{"id":"message","placeholder":"","rows":"10","required":""},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"rules":'required'}},[_c('div',{staticClass:"contact__privacy"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.privacy),expression:"privacy"}],staticClass:"cb",attrs:{"id":"contact__privacy-check","type":"checkbox","name":"","required":""},domProps:{"checked":Array.isArray(_vm.privacy)?_vm._i(_vm.privacy,null)>-1:(_vm.privacy)},on:{"change":function($event){var $$a=_vm.privacy,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.privacy=$$a.concat([$$v]))}else{$$i>-1&&(_vm.privacy=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.privacy=$$c}}}}),_vm._v(" "),_c('label',{attrs:{"for":"contact__privacy-check"}},[_vm._v("\n                        "+_vm._s(_vm.readPrivacyText)+"**\n                    ")]),_vm._v(" "),_c('p',{staticClass:"this__form",domProps:{"innerHTML":_vm._s(_vm.collectText)}})])]),_vm._v(" "),_c('div',{staticClass:"float-right"},[_c('button',{class:{ btn: true, font_caps: true, disabled: invalid},attrs:{"type":"submit","disabled":invalid}},[_vm._v("\n                    "+_vm._s(_vm.sendText)+"\n                ")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }