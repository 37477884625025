const StaticText = {
    bind(el, binding, vnode) {
        const { value } = binding
        const { context, data } = vnode
        const escapedText = context.escapedStaticText(value)
        const rawText = context.getStaticText(value)

        if (context.getStaticText == null) {
            throw new Error ('Please include GlobalMixin')
        }

        if (el.hasAttribute('placeholder')) {
            el.placeholder = escapedText + (data.attrs.required != null ? '*' : '')
            el.name = escapedText + (data.attrs.required != null ? '*' : '')
        } else {
            el.innerHTML = rawText
        }
    }
}

export default StaticText;
