//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "~/mixin/GlobalMixin"

export default {
    name: "SingleImageComponent",
    mixins: [GlobalMixin],
    props: {
        hasScroll: {
            type: Boolean,
            required: false,
            default: false
        },
        scrollSpeed: {
            type: Number,
            required: false,
            default: -3
        }
    },
    computed: {
        screenSz() {
            return window.innerHeight >= 1000 && window.innerWidth >= 1000
        }
    },
}
