//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from '@/mixin/GlobalMixin'
import FooterComponent from "@/components/global/FooterComponent/FooterComponent"
import SmoothScrollMixin from "@/mixin/animations/SmoothScrollMixin"
import LoadingMixin from "@/mixin/animations/LoadingMixin"
import {mapMutations, mapState} from 'vuex'
import CookieMixin from "~/mixin/helpers/CookieMixin"

export default {
    name: 'Wrapper',
    components: {FooterComponent},
    mixins: [GlobalMixin, LoadingMixin, SmoothScrollMixin, CookieMixin],
    scrollToTop: true,
    data: () => {
        return {
            vsSection: 'vs-section',
            isMobile: false
        }
    },
    head() {
        return {
            ...this.renderMetaPage
        }
    },
    computed: {
        ...mapState({
            loadingCompleted: state => state.SmoothScroll.loadingCompleted,
            isCookieBoxOpen: state => state.Animation.isCookieBoxOpen,
        }),
        cookieMessageText() {
            const text = this.getStaticText('cookie box - message')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        },
        cookieLinkText() {
            const text = this.getStaticText('cookie box - link text')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        },
        continueText() {
            const text = this.getStaticText('command - continue')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        },
        pageDataChange() {
            if (this.getPageData) {
                return this.getPageData
            }

            return null
        }
    },
    watch: {
        loadingCompleted() {
            if (!this.isCurrentUserCanEdit && (this.checkCookie(this.cookieName) !== this.cookieValue)) {
                this.setCookieBoxOpen(true);
            }
        },
        isCookieBoxOpen(val) {
            if (!val) {
                this.createCookie(this.cookieName, this.cookieValue, this.cookieDuration)
            }
        }
    },
    methods: {
        ...mapMutations({
            setCookieBoxOpen: 'setCookieBoxOpen'
        })
    },
    mounted() {
        const trackedPage = ['centrale-naselje', 'centrale-sosedstvo', 'centrale-neighbourhood']

        if (trackedPage.includes(this.$route.params.slug)) {
            var cdCampaignKey = 'CMP-01003-X2M3L';
            window.cdCampaignKey = cdCampaignKey;
        }
    }
}

