//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "~/mixin/GlobalMixin"
import LinkEditor2 from "@/directives/LinkEditorPlugin"

export default {
    name: "FullBannerComponent",
    directives: {LinkEditor2},
    mixins: [GlobalMixin]
}
