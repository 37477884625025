var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"section-config",rawName:"v-section-config",value:({ options: _vm.getOptions }),expression:"{ options: getOptions }"}],staticClass:"section",attrs:{"data-scroll-section":""}},[_c('div',{directives:[{name:"image-gallery-editor",rawName:"v-image-gallery-editor",value:({ model: _vm.getDataModel.slider }),expression:"{ model: getDataModel.slider }"}],staticClass:"q-container"},[_c('div',{ref:"slider",staticClass:"cards columns"},_vm._l((_vm.getDataModel.slider),function(slider,index){return _c('div',{directives:[{name:"image-editor",rawName:"v-image-editor",value:({
                    state: function (updated) { return (slider.image = updated); }
                }),expression:"{\n                    state: updated => (slider.image = updated)\n                }"}],key:index,staticClass:"card-slider"},[_c('div',{staticClass:"background-image",style:(("background-image:url('" + (_vm.renderImage(
                            slider.image
                        )) + "')"))}),_vm._v(" "),_c('div',{staticClass:"text-container"},[_c('p',{directives:[{name:"vold-text-editor",rawName:"v-vold-text-editor",value:({
                            state: function (updated) { return (slider.title = updated); }
                        }),expression:"{\n                            state: updated => (slider.title = updated)\n                        }"}],staticClass:"title"},[_vm._v("\n                        "+_vm._s(slider.title)+"\n                    ")]),_vm._v(" "),_c('h3',{directives:[{name:"vold-text-editor",rawName:"v-vold-text-editor",value:({
                            state: function (updated) { return (slider.details = updated); }
                        }),expression:"{\n                            state: updated => (slider.details = updated)\n                        }"}],staticClass:"details"},[_vm._v("\n                        "+_vm._s(slider.details)+"\n                    ")])])])}),0),_vm._v(" "),_c('div',{staticClass:"arrows"},[_c('div',{class:'arrow prev ' + ( _vm.isCurrentUserCanEdit ? 'arrow-edit ' : '' ),attrs:{"data-hover":"Prev"},on:{"click":_vm.navigate}}),_vm._v(" "),_c('div',{class:'arrow next ' + ( _vm.isCurrentUserCanEdit ? 'arrow-edit ' : '' ) + ( _vm.counter === 0 ? 'arrow-main ' : '' ),attrs:{"data-hover":"Next"},on:{"click":_vm.navigate}})])]),_vm._v(" "),_c('div',{staticClass:"nav-line"},[_c('div',{staticClass:"nav-line_container columns"},[_c('a',{staticClass:"nav-line_container-text",domProps:{"innerHTML":_vm._s(_vm.previousText)},on:{"click":function($event){return _vm.navigate('Prev')}}}),_vm._v(" "),_c('div',{staticClass:"nav-line_container-line"}),_vm._v(" "),_c('a',{staticClass:"nav-line_container-text",domProps:{"innerHTML":_vm._s(_vm.nextText)},on:{"click":function($event){return _vm.navigate('Next')}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }