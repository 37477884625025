//
//
//
//
//
//
//
//
//
//

import {triggerResize} from "~/plugins/helper";
import {GlobalMixin} from "~/mixin/GlobalMixin";
import 'vue-resize/dist/vue-resize.css';

export default {
    name: "ElfsightComponent",
    mixins: [GlobalMixin],
    methods: {
        triggerResize
    }
}
