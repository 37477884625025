import { render, staticRenderFns } from "./CarouselWideComponent.vue?vue&type=template&id=1ee1730c&scoped=true&"
import script from "./CarouselWideComponent.vue?vue&type=script&lang=js&"
export * from "./CarouselWideComponent.vue?vue&type=script&lang=js&"
import style0 from "../CarouselComponent/CarouselComponent.scss?vue&type=style&index=0&id=1ee1730c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ee1730c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CarouselComponent: require('/var/www/frontend-lustica-nuxt/components/tpl/banners/CarouselComponent/CarouselComponent.vue').default})
