//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "~/mixin/GlobalMixin";
import ColorPicker from "@/components/partials/ColorPicker/ColorPicker"
import {TweenMax} from 'gsap';
import Swappable from "@/mixin/utilities/Swappable"
import Swap from "@/components/partials/Swap/Swap"
import VoldTextEditor2 from "@/directives/TextEditable"

export default {
    name: "ImgTxtRightComponent",
    components: {
        Swap,
        ColorPicker,
    },
    directives: {VoldTextEditor2},
    mixins: [GlobalMixin, Swappable],
    computed: {
        shadow() {
            return this.getDataModel.shadow;
        }
    },
    watch: {
        'getDataModel.fontSize'(val) {
            if (this.getDataModel.fontSize != null && this.getDataModel.fontSize !== '0' &&
                this.getDataModel.fontSize !== '') {
                this.$refs.wawa.style.fontSize = val + 'px'
            } else {
                this.$refs.wawa.style.removeProperty('font-size')
            }
        },
        shadow(val) {
            TweenMax.to(this.$refs.shadow, .5, {
                backgroundColor: this.$refs.picker.bgColors[val]
            });
        }
    },
    mounted() {
        if (this.getDataModel.fontSize != null && this.getDataModel.fontSize !== '0' &&
            this.getDataModel.fontSize !== '') {
            this.$refs.wawa.style.fontSize = this.getDataModel.fontSize + 'px'
        }
    }
}
