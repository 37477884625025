import {TweenMax} from "gsap"

const HasBackgroundColor = {
    methods: {
        updateBg(val) {
            let el = this.$refs.section
            let number = el.querySelector('.background-number')

            if (el != null) {
                TweenMax.to(el, .5, {
                    backgroundColor: this.vBgColors[val]
                })
            }

            if (number != null) {
                TweenMax.to(number, .5, {
                    color: this.vColors[val]
                })
            }
        }
    },
    watch: {
        bgColor(val) {
            this.updateBg(val)
        },
    },
    computed: {
        vBgColors() {
            return {
                white: 'transparent',
                grey: '#E1E9EE',
                cream: '#F6ECE7',
                green: '#E1EAE1',
                gold: '#F4F2ED',
                bluish: '#D5E9F3'
            }
        },
        vColors() {
            return {
                white: 'rgba(211, 202, 183, 0.12)',
                grey: 'rgba(255, 255, 255, 0.49)',
                cream: 'rgba(255, 255, 255, 0.49)',
                green: 'rgba(255, 255, 255, 0.49)',
                gold: 'rgba(255, 255, 255, 0.49)',
                bluish: 'rgba(255, 255, 255, 0.49)',
            }
        },
        bgColor() {
            return this.getDataModel.bgColor
        }
    },
    mounted() {
        if (this.$store.getters.getCurrentUserCanEditContent) {
            this.updateBg(this.getDataModel.bgColor)
        }
    }
}

export default HasBackgroundColor
