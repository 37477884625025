//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex'
import {PostMixin} from "@/mixin/helpers/PostMixin"
import {CrudHelper} from "@/vold-core/functions/Crudhelper"
import {GlobalMixin} from "@/mixin/GlobalMixin"
import imagesLoaded from 'imagesloaded'
import {triggerResize} from "@/plugins/helper"
import {LanguageMixin} from "~/mixin/helpers/LanguageMixin";
import * as en from 'dayjs/locale/en';
import * as hr from 'dayjs/locale/hr';
import * as ru from 'dayjs/locale/ru';

export default {
    name: "BlogListComponent",
    mixins: [LanguageMixin, GlobalMixin, PostMixin],
    data() {
        return {
            blog: {},
            page: 0,
            msnry: null
        }
    },
    computed: mapState({
        loadingCompleted: state => state.SmoothScroll.loadingCompleted
    }),
    watch: {
        loadingCompleted() {
            this.initMsnry()
        },
        posts() {
            if (this.msnry != null) {
                imagesLoaded(this.$refs.row, () => {
                    this.initMsnry()
                    this.$nextTick(() => triggerResize())
                })
            }
        }
    },
    mounted() {
        if (window.Masonry == null) {
            window.Masonry = require('masonry-layout')
        }

        this.getBlog()
    },
    methods: {
        initMsnry() {
            this.msnry = new window.Masonry(this.$refs.row, {
                itemSelector: '.blog__list-column',
                columnWidth: '.blog__list-sizer',
                percentPosition: true
            })
        },
        formatDate(date) {
            const locales = {
                en, mne: hr, ru
            }

            return this.$dayjs(date).locale(locales[this.lang]).format('DD MMMM YYYY')
        },
        detailLink(slug) {
            return this.renderPostLink('blog',
                this.__(slug), true)
        },
        async getBlog(page = 0) {
            let response = await CrudHelper.GET('post', {
                where: {type: 'blog'},
                size: 4,
                skip: 0,
                sort: 'published_at,DESC',
                page
            })

            this.blog = response.data
            this.posts.push(...response.data.results)
            this.$nextTick(() => this.initMsnry())

            setTimeout(() => {
                triggerResize()
            }, 1000);
        },
        next() {
            if (this.page < this.blog.total_page) {
                ++this.page
                this.getBlog(this.page)
            }
        }
    }
}
