import { render, staticRenderFns } from "./BerthMarinaFormComponent.vue?vue&type=template&id=f8d7701c&scoped=true&"
import script from "./BerthMarinaFormComponent.vue?vue&type=script&lang=js&"
export * from "./BerthMarinaFormComponent.vue?vue&type=script&lang=js&"
import style0 from "./BerthMarinaFormComponent.scss?vue&type=style&index=0&id=f8d7701c&scoped=true&lang=scss&"
import style1 from "./BerthMarinaFormComponent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8d7701c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Template: require('/var/www/frontend-lustica-nuxt/components/tpl/template.js').default})
