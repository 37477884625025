import { render, staticRenderFns } from "./TextIntroComponent.vue?vue&type=template&id=4c6c38ec&scoped=true&"
import script from "./TextIntroComponent.vue?vue&type=script&lang=js&"
export * from "./TextIntroComponent.vue?vue&type=script&lang=js&"
import style0 from "./TextIntroComponent.scss?vue&type=style&index=0&id=4c6c38ec&lang=scss&scoped=true&"
import style1 from "./TextIntroComponent.vue?vue&type=style&index=1&lang=scss&v-if=isCurrentUserCanEdit&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c6c38ec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DisposableSingleImage: require('/var/www/frontend-lustica-nuxt/components/partials/DisposableSingleImage/DisposableSingleImage.vue').default})
