//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"
import {TweenMax, Power4} from 'gsap'
import CursorMixin from "@/mixin/animations/CursorMixin"

export default {
    name: "StackGalleryComponent",
    mixins: [GlobalMixin, CursorMixin],
    data() {
        return {
            page: 0
        }
    },
    computed: {
        previousText() {
            const text = this.getStaticText('command - previous')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        },
        nextText() {
            const text = this.getStaticText('command - next')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        }
    },
    methods: {
        navigate(flag) {
            let itemsPerPage = 5;
            let itemsWidth = 70;

            if (window.innerWidth <= 576) {
                itemsPerPage = 1.5;
                itemsWidth = 90;
            }

            let max = Math.ceil(this.getDataModel.gallery.length / itemsPerPage)

            let direction = 0;

            if (flag === 'prev' && this.page - 1 >= 0) {
                direction = -Math.abs((--this.page) * itemsWidth)
            } else if (flag === 'next' && this.page + 1 < max) {
                direction = -Math.abs((++this.page) * itemsWidth)
            } else {
                return;
            }

            if (window.innerWidth <= 576) {
                TweenMax.to(this.$refs.columns, 1, {
                    xPercent: -Math.abs(this.page * 100),
                    ease: Power4.easeOut
                })
            } else {
                TweenMax.to(this.$refs.columns, 1, {
                    x: direction + 'vw',
                    ease: Power4.easeOut
                })
            }
        },
    },
}
