//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"
import {TweenMax} from "gsap"
import {mapState} from "vuex"

export default {
    name: "BannerDockComponent",
    mixins: [GlobalMixin],
    props: {
        titlePlaceholder: {
            type: Array,
            required: false
        }
    },
    computed: {
        ...mapState({
            smoothScroll: state => state.SmoothScroll.smoothScroll
        }),
    },
    mounted() {
        const revealItems = this.$refs.headerAnimate.querySelectorAll('.reveal_item')
        TweenMax.set(revealItems, {
            skewY: 5,
            yPercent: 115,
        })
    },
    methods: {
        onRegister() {
            this.smoothScroll.scrollTo(document.querySelector('.section.section___container .contact-form'))
        }
    }
}
