import { render, staticRenderFns } from "./BoxLeftComponent.vue?vue&type=template&id=67f0b4ee&scoped=true&"
import script from "./BoxLeftComponent.vue?vue&type=script&lang=js&"
export * from "./BoxLeftComponent.vue?vue&type=script&lang=js&"
import style0 from "./BoxLeftComponent.scss?vue&type=style&index=0&id=67f0b4ee&lang=scss&scoped=true&"
import style1 from "./BoxLeftComponent.vue?vue&type=style&index=1&lang=scss&v-if=isCurrentUserCanEdit&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f0b4ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DisposableSingleImage: require('/var/www/frontend-lustica-nuxt/components/partials/DisposableSingleImage/DisposableSingleImage.vue').default,ColorPicker: require('/var/www/frontend-lustica-nuxt/components/partials/ColorPicker/ColorPicker.vue').default})
