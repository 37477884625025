//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"
import ImageGalleryEditor2 from '@/directives/ButtonGalleryEditorPlugin'

export default {
    name: "EventsComponent",
    directives: {ImageGalleryEditor2},
    mixins: [GlobalMixin],
    methods: {
        getSliderById(id) {
            const result = this.getDataModel.events.filter(item => item.id === id)

            if (result.length > 0) {
                return result[0]
            }
        },
        getImageModel(id, updated) {
            const result = this.getSliderById(id)

            if (result != null) {
                result.image = updated
            }
        },
    }
}
