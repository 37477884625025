import {Expo, TimelineLite} from "gsap"

const Swappable = {
    methods: {
        onSwap() {
            let tl = new TimelineLite;

            tl.to(this.$refs.section, .25, {
                opacity: 0,
                ease: Expo.easeOut,
                onComplete: () => {
                    this.getDataModel.reversed = !this.getDataModel.reversed;
                }
            }).to(this.$refs.section, .25, {
                opacity: 1,
                ease: Expo.easeIn
            })
        }
    },
}

export default Swappable;
