//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "~/mixin/GlobalMixin";

export default {
    name: "BoxBottomLeftComponent",
    mixins: [GlobalMixin]
}
