//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapState} from "vuex"
import {GlobalMixin} from "~/mixin/GlobalMixin"

export default {
    name: "LocalTimeComponent",
    mixins: [GlobalMixin],
    computed: {
        time() {
            return this.$dayjs().tz('Europe/Podgorica').format('HH:mm')
        },
        divider() {
            return this.$dayjs().tz('Europe/Podgorica').format('A')
        },
        ...mapState({
            weather: state => state.Weather.weather
        })
    },
    methods: mapActions('Weather', [
        "requestWeather"
    ]),
    mounted() {
        this.requestWeather('radovići')
    }
}
