import {COMPONENTS} from '../../components/tpl/template'
import {isEmptyObject} from '../functions/helpers'

export const VoldPostContentMixin = {
    props: {
        model: {
            type: Object | String | Array,
            default: null
        }
    },

    data() {
        return {
            allData: {},
            currentPage: {}
        }
    },
    computed: {

        getOptions() {
            return this.options
        },

        getModel() {
            return this.model
        },

        /**
         * Getter current selected language
         * @return {WebsiteContentStore.getters.getSelectedWebsiteLanguage}
         */
        getLanguage() {
            return this.$store.getters.getSelectedWebsiteLanguage
        },

        getComponents() {
            const components = this.$store.getters.getCurrentPostDetailComponent
            if (components) {
                if (components[this.getLanguage]) {
                    return components[this.getLanguage]
                }
            } else {
                return components['en']
            }
            return []
        },

        getPostDetailType() {
            const pageDetail = this.$store.getters.getCurrentPostDetail
            return !!(pageDetail && !!pageDetail.type)
        },


        getPostTypeComponent() {
            const pageDetail = this.$store.getters.getCurrentPostDetail
            if (pageDetail && pageDetail.type) {
                return `post-${pageDetail.type}`
            }
        }
    },

    methods: {

        //not optimize yet
        // mergeOptions(optionsData,compName){
        //     const comp = COMPONENTS.find(comp => comp.module_name === compName)
        //     if(comp && comp.options){
        //             Object.keys(comp.options).map(key=>{
        //             comp.options[key] = optionsData[key]?optionsData[key]:comp.options[key]
        //             return key;
        //         })
        //         return comp.options
        //     }

        //     return optionsData
        // },
        

        renderComponentOptions(data, compName) {
            if (!data) return {}
            if (!compName) compName = data.module_name
            const comp = COMPONENTS.find(comp => comp.module_name === compName)
            if (data && isEmptyObject(data.options) && comp && comp.options) data.options = comp.options
            return data.options || {}
        }
    }
}
