import {
    HeroVideoSliderComponent,
    FullBannerComponent,
    CarouselComponent,
    CarouselWideComponent,
    SingleImageComponent,
    DiscoverComponent,
    BannerDockComponent,
    ThreeCardsSliderComponent,
    ImageSliderComponent,
    BannerScrollComponent,
} from "~/components/tpl/banners";

import {
    BoxIntroComponent,
    BoxRightComponent,
    BoxLeftComponent,
    EventsComponent,
    StackGalleryComponent,
    BoxBottomLeftComponent,
    SliderCardComponent,
    MapBoxComponent,
    BoxRightTabComponent,
    LocationBoxComponent,
    MapComponent
} from "~/components/tpl/boxes";

import {
    ImgTxtRightComponent,
    TwoImagesComponent
} from "~/components/tpl/imgtxts";

import {
    LocalTimeComponent,
    TextIntroComponent,
    ArticleComponent
} from "~/components/tpl/texts"

import ThreeCardsComponent from "~/components/tpl/cards/ThreeCardsComponent";
import TextButtonComponent from "~/components/tpl/buttons/TextButtonComponent/TextButtonComponent";
import ContactFormComponent from "@/components/tpl/forms/ContactFormComponent/ContactFormComponent"

import {EventPostComponent} from "@/components/tpl/posts";
import SliderProgressComponent from "@/components/tpl/sliders/SliderProgressComponent/SliderProgressComponent"
import BlogListComponent from "@/components/tpl/lists/BlogListComponent/BlogListComponent"
import JobListComponent from "@/components/tpl/lists/JobListComponent/JobListComponent"
import MarinaFormComponent from "~/components/tpl/forms/MarinaFormComponent/MarinaFormComponent"
import BerthMarinaFormComponent from "~/components/tpl/forms/BerthMarinaFormComponent/BerthMarinaFormComponent"
import ElfsightComponent from "~/components/tpl/ext/Elfsight/ElfsightComponent";

export const Components = {
    HeroVideoSliderComponent,
    BoxIntroComponent,
    LocalTimeComponent,
    BoxRightComponent,
    ImgTxtRightComponent,
    FullBannerComponent,
    CarouselComponent,
    TwoImagesComponent,
    ThreeCardsComponent,
    TextButtonComponent,
    BoxLeftComponent,
    CarouselWideComponent,
    TextIntroComponent,
    DiscoverComponent,
    EventsComponent,
    StackGalleryComponent,
    BannerDockComponent,
    BoxBottomLeftComponent,
    ThreeCardsSliderComponent,
    ImageSliderComponent,
    SingleImageComponent,
    SliderCardComponent,
    BannerScrollComponent,
    ContactFormComponent,
    EventPostComponent,
    SliderProgressComponent,
    MapBoxComponent,
    BoxRightTabComponent,
    LocationBoxComponent,
    MapComponent,
    BlogListComponent,
    ArticleComponent,
    JobListComponent,
    MarinaFormComponent,
    BerthMarinaFormComponent,
    ElfsightComponent,
}
