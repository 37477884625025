//
//
//
//
//
//
//
//
//
//

export default {
    name: "ColorPicker",
    props: {
        value: {
            type: String,
            required: true,
            default: 'white'
        },
    },
    computed: {
        bgColors() {
            return {
                white: 'transparent',
                cream: '#F6ECE7',
                green: '#E1EAE1',
                bluish: '#D5E9F3'
            }
        },
        colors() {
            return {
                white: 'rgba(211, 202, 183, 0.12)',
                cream: 'rgba(255, 255, 255, 0.49)',
                green: 'rgba(255, 255, 255, 0.49)',
                bluish: 'rgba(255, 255, 255, 0.49)',
            }
        },
    }
}
