//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "~/mixin/GlobalMixin"
import Carousel from "~/plugins/carousel"
import {triggerResize} from "@/plugins/helper"
import CursorMixin from "@/mixin/animations/CursorMixin"
import ImageGalleryEditor2 from '@/directives/ButtonGalleryEditorPlugin'
import LinkEditor2 from "@/directives/LinkEditorPlugin"

export default {
    name: "CarouselComponent",
    directives: {ImageGalleryEditor2, LinkEditor2},
    mixins: [GlobalMixin, CursorMixin],
    props: {
        wide: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            carousel: null
        }
    },
    computed: {
        totalSlider() {
            return this.getDataModel.slider.length
        }
    },
    watch: {
        totalSlider() {
            triggerResize()
        }
    },
    mounted() {
        this.initCarousel()
    },
    methods: {
        getSliderById(id) {
            const result = this.getDataModel.slider.filter(item => item.id === id)

            if (result.length > 0) {
                return result[0]
            }
        },
        getImageModel(id, updated) {
            const result = this.getSliderById(id)

            if (result != null) {
                result.image = updated
            }
        },
        getTitleModel(id, title) {
            const result = this.getSliderById(id)

            if (result != null) {
                result.title = title
            }
        },
        getLinkModel(id) {
            const result = this.getSliderById(id)

            if (result != null) {
                return result.link
            }
        },
        initCarousel() {
            var options = {
                duration: 500,
                dragSpeed: 1.5,
                element: this.$refs.carousel
            }

            this.carousel = new Carousel(options)
        },
    }
}
