import ENV from '../../environment/ENV'
import {copy} from '../functions/helpers'
import {COMPONENTS} from '../../components/tpl/template'
import {v1 as uuidv4} from 'uuid'
import _ from 'lodash'

export const VoldPMAction = {
    methods: {
        async PMActions(event) {

            const message = event.message
            /**
             * Dashboard will request new data updated before it save
             * @message component language listing
             */
            if (event.key === 'REQ_PB_DATA') {
                this.sendMessageToParentWindow('SAVE_PB_DATA', this.$store.getters.getCurrentPostDetailComponent)
            }

            /**
             * Get list of components
             */
            if (event.key === 'GET_COMPONENTS') {

                this.sendMessageToParentWindow('GET_COMPONENTS', COMPONENTS)
            }

            // Get component registered
            if (event.key === 'GET_REGISTERED_COMPONENTS') {
                this.getRegisterComponent()
            }


            /**
             * Sort section
             */
            if (event.key === 'SORT_WEBSITE_SECTION') {
                const getComponent = this.$store.getters.getCurrentPostDetailComponent
                const cloneComponents = _.cloneDeep(getComponent[this.getLanguage])
                const orderResolveComponent = () => {

                    return new Promise(resolve => {
                        const newUpdatedComponent = []

                        if (message.data) {
                            message.data.map(updated => {
                                newUpdatedComponent.push(cloneComponents[updated.originalSortIndex])
                            })
                        }

                        resolve(newUpdatedComponent)
                    })

                }

                const updatedComponent = await orderResolveComponent()

                if (getComponent) {
                    getComponent[this.getLanguage] = updatedComponent
                }

                this.$store.commit('setCurrentPostDetailComponents', getComponent)
                this.$root.$emit('sortWebsiteSection', true)


                this.getRegisterComponent()

            }


            /**
             * Add more section
             */
            if (event.key === 'ADD_MORE_SECTION') {
                this.setCurrentPostComponents(message)
                this.$root.$emit('addedNewSection', true)
            }


            /**
             * Delete section
             */
            if (event.key === 'DELETE_SECTION') {
                const getComponent = this.$store.getters.getCurrentPostDetailComponent
                if (getComponent) getComponent[this.getLanguage] = message
                this.$store.commit('setCurrentPostDetailComponents', getComponent)
                this.$root.$emit('deleteWebsiteSection', true)
            }


            if (event.key === 'GET_WEBSITE_DETAIL_BEFORE_BACK') {
                if (message) {
                    this.sendMessageToParentWindow('WEBSITE_DETAIL_BEFORE_BACK', this.$store.getters.getCurrentPostDetailComponent)
                }
            }
        },


        getRegisterComponent() {
            const components = this.$store.getters.getCurrentPostDetailComponent
            const clone = _.cloneDeep(components[this.getLanguage])
            const cleanComponent = []
            clone.map((sec, index) => {
                if (sec) {
                    sec.originalSortIndex = index
                    cleanComponent.push(sec)
                }
            })

            this.sendMessageToParentWindow('GET_REGISTERED_SECTIONS', cleanComponent)
        },


        setCurrentPostComponents(index) {
            let getComponent = this.$store.getters.getCurrentPostDetailComponent

            /**
             * Close default data from template component
             */
            const clone = copy(COMPONENTS[index])


            /**
             * Give an ID to component
             */
            clone.id = uuidv4()
            /**
             * Add components
             */


            getComponent[this.getLanguage].push(clone)

            /**
             * Update whole components with languages
             */
            this.$store.commit('setCurrentPostDetailComponents', getComponent)


            /**
             * Update current block
             */
            this.$store.commit('setCurrentPostComponentsBlock', getComponent[this.getLanguage])

            this.$root.$emit('addedNewComponent', true)


            const blockComponent = _.cloneDeep(getComponent[this.getLanguage])

            if (blockComponent) {
                blockComponent.map((block, index) => {
                    block.originalSortIndex = index
                })
            }

            this.sendMessageToParentWindow('GET_REGISTERED_SECTIONS', blockComponent)
        },

        sendMessageToParentWindow(key, message) {

            let client = ENV().clientOrigin

            /**
             * @since v1.0.1
             */
            if (!client) {
                client = ENV().client_origin
            }

            window.parent.postMessage({
                key: key,
                message: message
            }, client)
        }
    }
}
