//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"
import VoldTextEditor2 from "@/directives/TextEditable"

export default {
    name: "ArticleComponent",
    directives: {VoldTextEditor2},
    mixins: [GlobalMixin]
}
