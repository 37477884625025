//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "~/mixin/GlobalMixin"
import {TweenMax} from 'gsap'
import {mapState} from "vuex"

export default {
    name: "BannerScrollComponent",
    mixins: [GlobalMixin],
    watch: {
        loadingCompleted(val) {
            const revealItems = this.$refs.headerAnimate.querySelectorAll('.reveal_item')
            TweenMax.set(revealItems, {
                skewY: 5,
                yPercent: 115,
            })
        }
    },
    computed: {
        ...mapState({
            loadingCompleted: state => state.SmoothScroll.loadingCompleted
        }),
        scrollDownText() {
            const text = this.getStaticText('command - scroll down')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null;
        }
    },
}
