//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "~/mixin/GlobalMixin";

export default {
    name: "TextButtonComponent",
    mixins: [GlobalMixin]
}
