import ENV from "@/environment/ENV"
import {createComponent} from "@/vold-core/functions/helpers"


const VoldTextEditor2 = {
    bind: (el, binding, vnode) => {
        let {rich, html, disable, buttonMedia, buttons, state, removeBreak} = binding.value
        const isUserCanEdit = vnode.context.$store.getters.getCurrentUserCanEditContent
        let text

        if (rich || html) {
            text = el.innerHTML
        } else {
            text = el.innerText

        }

        if (isUserCanEdit && !disable) {
            el.innerHTML = text || 'Type some text'
            el.classList.add('vold__text-editor')
            el.setAttribute('placeholder', 'Write some text')

            /**
             * Set for rich editor
             */
            if (rich || html) {
                const MediumEditor = require('medium-editor')
                let buttonsToolbar = ['bold', 'italic', 'underline', 'h1', 'h2', 'h3', 'unorderedlist', 'anchor']

                if (buttons && buttons.length > 0) {
                    buttonsToolbar.push(...buttons)
                }

                const options = {
                    placeholder: {
                        text: 'Type your text',
                        hideOnClick: true
                    },

                    toolbar: {
                        buttons: buttonsToolbar
                    },

                    paste: {
                        forcePlainText: true,
                        cleanPastedHTML: false,
                        cleanReplacements: [],
                        cleanAttrs: ['class', 'style', 'dir', 'script'],
                        cleanTags: ['meta']
                    }
                }


                if (html) {
                    options.toolbar = false
                }


                const medium = new MediumEditor(el, options)


                const button = new ButtonMedia(el, vnode, medium)


                medium.subscribe('editableInput', () => {
                    if (buttonMedia && rich) button.createButtonMedia()
                })


                medium.subscribe('blur', () => {
                    el.classList.remove('focus-editing')
                    let content = medium.getContent()
                    /**
                     * Replacing all unused &nbsp; string
                     * @type {string|void|never}
                     */
                    content = content.replace(/&nbsp;/g, '')
                    if (binding.value.state) binding.value.state(content)
                })


                medium.subscribe('focus', () => {
                    el.classList.add('focus-editing')
                })

                if (buttonMedia) {
                    el.addEventListener('keyup', () => {
                        if (buttonMedia) button.createButtonMedia()
                    })
                    medium.subscribe('editableClick', () => {
                        button.createButtonMedia()
                    })
                }


                return
            }


            /**
             * Normal editor
             */
            el.setAttribute('contentEditable', true)
            el.addEventListener('input', (event) => {
                event.preventDefault()
                event.stopPropagation()

                let content = ''

                if (removeBreak) {
                    content = el.innerText.trim()
                } else {
                    content = el.innerText.replace(/(?:\r\n|\r|\n)/g, '<br>')
                }

                if (state) state(content)
            })


            /**
             * Clear line for paste the dirt code
             */
            el.addEventListener('paste', ev => {
                ev.preventDefault()
                const text = ev.clipboardData.getData('text/plain')
                document.execCommand('insertHTML', false, text)
            })

        } else {

            if (text) {
                if (html || rich) {
                    el.innerHTML = text.replace(/&nbsp;/g, '')
                } else {
                    el.innerHTML = text
                }
            } else {
                el.style.display = 'none'
            }

        }
    }
}


class ButtonMedia {

    constructor(el, vnode, medium) {
        const {context: {$root}} = vnode
        this._el = el
        this._vRoot = $root
        this._button = null
        this._medium = medium
        this._imageId = null
        this.buttonComponent = () => ({
            component: import('@/vold-core/component-editor/ButtonMedia/ButtonMedia')
        })
        this._removeButton()
    }


    createButtonMedia() {
        const paragraph = this._medium.getSelectedParentElement()
        const hasContentText = paragraph.innerHTML.replace(/<br>/g, '')

        if (!hasContentText) {
            this._removeButton()

            createComponent(this.buttonComponent().component, comp => {
                this._imageId = comp.buttonId
                this._button = comp.$el
                this._containerDetail = this._el.getBoundingClientRect()
                this._button.style.left = `${this._containerDetail.x - 20}px`
                this._button.style.top = `${this._containerDetail.y - 20}px`
                this._button.classList.add('medium-button-media')
                this._vRoot.$el.appendChild(comp.$el)

                this._listenImagePostMessage(file => {
                    if (file) {
                        const image = `<img src="${ENV().asset_url}${file.path}" width="100%" height="auto" />`
                        paragraph.innerHTML = `<div class="__image-display__ful-width" style="width: 100%; height: auto">${image}</div>`
                    }
                })
            })


        } else {
            this._removeButton()
        }
    }


    _removeButton() {
        if (this._button) {
            this._button.parentNode.removeChild(this._button)
            this._button = null
        }
    }

    _listenImagePostMessage(fn) {
        this._vRoot.$on('PM', event => {
            if (!event) return
            const {message} = event
            if (event.key === 'SELECTED_FILE') {
                if (message.to === this._imageId) {
                    if (fn) fn(message.file)
                }
            }
        })
    }
}

export default VoldTextEditor2
