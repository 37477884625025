import { render, staticRenderFns } from "./ColorPicker.vue?vue&type=template&id=5cab1bfc&scoped=true&"
import script from "./ColorPicker.vue?vue&type=script&lang=js&"
export * from "./ColorPicker.vue?vue&type=script&lang=js&"
import style0 from "./ColorPicker.scss?vue&type=style&index=0&id=5cab1bfc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cab1bfc",
  null
  
)

export default component.exports