import Vue from 'vue'
import get from 'lodash/get'

const LinkEditor2 = {
    bind: (el, binding, vnode) => {
        const {context} = vnode
        const {value} = binding
        const isUserCanEdit = context.$store.getters.getCurrentUserCanEditContent

        if (isUserCanEdit) {

            el.classList.add('editor__active')

            el.addEventListener('click', event => {
                event.preventDefault()
                event.stopPropagation()


                /**
                 * Inject form into root document
                 * @returns {{component: (Promise<*>|*)}}
                 */
                const asyncComponent = () => ({
                    component: import('@/vold-core/component-editor/LinkEditor/LinkEditorForm/LinkEditorForm')
                })


                asyncComponent().component.then(comp => {
                    const newComp = Vue.extend(comp.default)
                    const form = new newComp()
                    const root = context.$root.$el

                    form.model = value.model()
                    form.$mount()
                    form.isActive = true
                    root.appendChild(form.$el)

                    try {
                        form.$el.parentNode.removeChild(form.$el.parentNode)
                    } catch (e) {
                        console.log('Element not registered')
                    }


                    /**
                     * On close remove element
                     */
                    form.$on('onClose', () => {
                        form.$el.parentNode.removeChild(form.$el)

                        // el.innerText = value.model.title
                    })

                })
            })

        } else {
            el.addEventListener('click', event => {

                event.preventDefault()
                event.stopPropagation()
                const target = el.getAttribute('target')
                const href = el.getAttribute('href')
                const url = get(value, 'model.url')

                /**
                 * @format
                 * sampe fn!=eventName:Value
                 */
                if (url && url.indexOf('fn!') > -1) {
                    const clean = url.replace('fn!=', '')
                    const action = clean.split(':')
                    context.$root.$emit(action[0], action[1])
                    return
                }


                if (!target) {
                    if (href.indexOf('http') > -1 || href.indexOf('www') > -1 || href.indexOf('mailto') > -1) {
                        window.open(href)
                    } else {
                        if (href.indexOf('#') > -1) {
                            window.location.href = href
                        } else {
                            context.$router.push(href)
                        }
                    }
                } else {
                    window.open(href)
                }
            })
        }
    }
}


export default LinkEditor2
