//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapState} from 'vuex'
import {GlobalMixin} from "@/mixin/GlobalMixin"
import {triggerResize} from "@/plugins/helper"
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

export default {
    name: "EventPostComponent",
    mixins: [GlobalMixin],
    data() {
        return {
            mode: 0,
            opened: false,
            iso: null
        }
    },
    watch: {
        mode() {
            this.opened = false
        },
        loadingCompleted() {
            this.initIso()
        },
        events() {
            this.initIso()

            this.$nextTick(() => {
                triggerResize()
            })
        }
    },
    computed: {
        ...mapState({
            event: state => state.Event.event,
            loadingCompleted: state => state.SmoothScroll.loadingCompleted,
        }),
        events() {
            return this.event.results
        },
    },
    methods: {
        ...mapActions('Event', [
            'requestEvent'
        ]),
        upcoming(item) {
            const date = this.$dayjs(item.data.date).startOf('month');
            if (date.isValid()) {
                return date.isSameOrAfter(this.$dayjs().startOf('month'))
            }

            return false;
        },
        async next() {
            this.$store.commit('Event/next')
            await this.requestEvent()

            triggerResize()
        },
        renderDate(date) {
            return this.$dayjs(date).format('YY - MM')
        },
        initIso() {
            if (window.Isotope == null) {
                window.Isotope = require('isotope-layout')
            }

            if (this.iso != null) {
                this.iso.destroy()
            }

            this.iso = new window.Isotope('.isotope', {
                itemSelector: '.event-post-item',
                percentPosition: true,
                masonry: {
                    columnWidth: '.grid-sizer',
                },
            })
        },
        onFilter(val) {
            this.initIso()
            this.mode = val === "*" ? 0 : (val === ".upcoming" ? 1 : 2)
            this.iso.arrange({filter: val})

            triggerResize()
        }
    },
    created() {
        this.$dayjs.extend(isSameOrAfter)
    },
    mounted() {
        this.initIso()
        this.requestEvent()

        window.addEventListener('resize', () => {
            this.initIso()
        })

        window.addEventListener('click', (e) => {
            if (this.opened) {
                if (e.target.classList.length === 0 ||
                    !e.target.classList[0].startsWith('navigation-mobile')) {
                    this.opened = false
                }
            }
        })
    }
}
