import { render, staticRenderFns } from "./TwoImagesComponent.vue?vue&type=template&id=c2854cca&scoped=true&"
import script from "./TwoImagesComponent.vue?vue&type=script&lang=js&"
export * from "./TwoImagesComponent.vue?vue&type=script&lang=js&"
import style0 from "./TwoImagesComponent.vue?vue&type=style&index=0&id=c2854cca&lang=scss&scoped=true&"
import style1 from "./TwoImagesComponent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2854cca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Swap: require('/var/www/frontend-lustica-nuxt/components/partials/Swap/Swap.vue').default,ColorPicker: require('/var/www/frontend-lustica-nuxt/components/partials/ColorPicker/ColorPicker.vue').default})
