//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import qSlide from "~/plugins/slider"
import {CursorMixin} from '~/mixin/animations'
import {GlobalMixin} from "@/mixin/GlobalMixin"

export default {
    name: "ImageSlider",
    mixins: [GlobalMixin, CursorMixin],
    computed:{
        sliderLength(){
            return this.getDataModel.slider.length
        }
    },
    watch: {
        sliderLength(){
            this.$nextTick(() => qSlide(this.$refs.q_slide));
        }
    },
    mounted() {
        qSlide(this.$refs.q_slide)
    },
}
