//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {ValidationObserver, ValidationProvider, extend, localize} from 'vee-validate'
import {VueSelect} from 'vue-select'
import {startCase} from "lodash"
import {CrudHelper} from "~/vold-core/functions/Crudhelper"
import {GlobalMixin} from "~/mixin/GlobalMixin"
import dayjs from "dayjs"
import StaticText from "~/directives/StaticText";

import en from 'vee-validate/dist/locale/en.json'
import mne from 'vee-validate/dist/locale/sr_Latin.json'
import ru from 'vee-validate/dist/locale/ru.json'

export default {
    name: "BerthMarinaFormComponent",
    components: {
        ValidationObserver, ValidationProvider, VueSelect,
    },
    directives: {
        StaticText
    },
    mixins: [GlobalMixin],
    data: function () {
        return {
            privacy: null,
            boatTypes: [
                'Motor', 'Sailing', 'Cataraman', 'Trimaran',
            ],
            electricities: [
                '16A', '32A', '63A', '125A',
            ],
            range: {
                start: new Date(),
                end: new Date(),
            },
            form: {
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                boatName: null,
                lengthOverall: null,
                boatModel: null,
                boatType: null,
                requiredElectricity: null,
                from: null,
                to: null
            }
        }
    },
    computed: {
        readPrivacyText() {
            return this.escapedStaticText('form - read privacy policy')
        },
        collectText() {
            const text = this.getStaticText('form - collect')

            if (text != null) {
                return text.replace(/<(\/|)p>/g, '').replace(/<b>(.*?)<\/b>/,
                    `<a class="btn collect__link" href="${this.renderLink(this.getCurrentLanguage + '/privacy-policy')}" target="_blank" rel="noopener noreferrer">$1</a>`)
            }

            return null
        },
        minDate() {
            return dayjs().startOf('day').toDate()
        },
        sendText() {
            const text = this.getStaticText('command - send')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        }
    },
    created() {
        const messages = {en, mne, ru}

        if (Object.hasOwnProperty.call(messages, this.getCurrentLanguage)) {
            localize(this.getCurrentLanguage, messages[this.getCurrentLanguage])
        }
    },
    mounted() {
        if (window.rules == null) {
            window.rules = require('vee-validate/dist/rules')
        }

        extend('required', window.rules.required)
        extend('email', window.rules.email)
        extend('nosymbol', this.nosymbol)
    },
    methods: {
        async onSubmit() {
            let data = []
            this.form.from = this.formatDate(this.range.start)
            this.form.to = this.formatDate(this.range.end)

            for (let key of Object.keys(this.form)) {
                data.push({
                    key: startCase(key),
                    value: this.form[key],
                })
            }

            if (window.location.hostname.match(/lusticabay\.com$/) != null) {
                await CrudHelper.POST('contact-us', {
                    data,
                    type: 'contact-us',
                    template: 'contact us lustica',
                    language: 'en',
                    autoRender: true,
                    recipients: [
                        {
                            name: 'marina@lusticadevelopment.com',
                            //email:'gusna@fleava.com',
                            email: 'marina@lusticadevelopment.com'
                        }
                    ]
                })
            }

            if (this.getOptions?.customFieldValues?.after_submit_link) {
                await this.$router.push(this.getOptions.customFieldValues.after_submit_link)
            } else {
                if (this.getCurrentLanguage === 'mne') {
                    await this.$router.push('/' + this.getCurrentLanguage + '/hvala')
                } else if (this.getCurrentLanguage === 'ru') {
                    await this.$router.push('/' + this.getCurrentLanguage + '/spasibo')
                } else {
                    await this.$router.push('/' + this.getCurrentLanguage + '/thank-you')
                }
            }
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
    }
}
