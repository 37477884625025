//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {CursorMixin} from '~/mixin/animations'
import {GlobalMixin} from "@/mixin/GlobalMixin"
import {TweenMax, Power4} from 'gsap'
import ColorPicker from "@/components/partials/ColorPicker/ColorPicker"
import HasBackgroundColor from "@/mixin/utilities/HasBackgroundColor"

export default {
    name: "SliderCardComponent",
    components: {ColorPicker},
    mixins: [GlobalMixin, CursorMixin, HasBackgroundColor],
    data() {
        return {
            page: 0
        }
    },
    computed: {
        previousText() {
            const text = this.getStaticText('command - previous')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        },
        nextText() {
            const text = this.getStaticText('command - next')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        }
    },
    methods: {
        navigate(e) {
            let percentage = (e.clientX / window.innerWidth) * 100
            let itemsPerPage = 1
            let itemsWidth = this.$refs.gallery.querySelector('.column').offsetWidth

            let max = Math.ceil(this.getDataModel.gallery.length / itemsPerPage)
            let direction = 0

            if (percentage <= 30 && this.page - 1 >= 0) {
                direction = -Math.abs((--this.page) * itemsWidth)
            } else if (percentage >= 70 && this.page + 1 < max) {
                direction = -Math.abs((++this.page) * itemsWidth)
            } else {
                return
            }

            TweenMax.to(this.$refs.columns, .5, {
                x: direction + 'px',
                ease: Power4.easeInOut
            })
        },
    },
}
