//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GlobalMixin } from "~/mixin/GlobalMixin";
import { CursorMixin } from "~/mixin/animations";
import { TweenMax, Power1 } from "gsap";

export default {
    name: "ThreeCardsSliderComponent",
    mixins: [GlobalMixin, CursorMixin],
    data() {
        return {
            counter: 0,
            innerWidth: 0,
            slidesToShow: 0,
            slidePerPage: 0,
            slideWidth: 0,
            sliderWidth: 0
        };
    },

    computed: {
        phone() {
            return this.innerWidth <= 500;
        },
        tablet() {
            return this.innerWidth <= 769;
        },
        sliderLengthCount() {
            return this.getDataModel.slider.length
        },
        previousText() {
            const text = this.getStaticText('command - previous')

            if (text != null) {
                return text.replace(/<.*?>/, '')
            }

            return null
        },
        nextText() {
            const text = this.getStaticText('command - next')

            if (text != null) {
                return text.replace(/<.*?>/, '')
            }

            return null
        }
    },

    watch:  {
        sliderLengthCount() {
            this.initSlider();
        }
    },

    mounted() {
        this.initSlider();
        window.addEventListener("resize", () => {
            this.initSlider();
        });
    },

    methods: {
        initSlider() {
            let card = document.querySelector(".card-slider");
            let marginRight = parseFloat(
                getComputedStyle(card)
                    .getPropertyValue("margin-right")
                    .split("px")[0]
            );
            this.innerWidth = window.innerWidth;
            this.slidesToShow = this.phone ? 1 : this.tablet ? 2 : 3;
            this.slidePerPage = this.phone ? 1 : this.tablet ? 2 : 3;
            this.slideWidth = card.getBoundingClientRect().width + marginRight;
            this.sliderWidth = this.slideWidth * this.sliderLengthCount;
            this.counter = 0;
            let { slider } = this.$refs;
            slider.style.width = `${this.sliderWidth.toString()}px`;
        },
        navigate(e) {
            let arrow = e === "Next" ? "Next" : (e === "Prev" ? "Prev":  e.currentTarget.getAttribute("data-hover"));
            let width = () => {
                switch (arrow) {
                case "Next":
                    if (
                        this.counter ===
                            this.sliderLengthCount - this.slidePerPage
                    ) {
                        this.counter = 0
                        return 0;
                    } else {
                        let slideLeft =
                                this.sliderLengthCount -
                                this.counter -
                                this.slidePerPage;
                        this.counter =
                                slideLeft < this.slidesToShow
                                    ? slideLeft + this.counter
                                    : this.slidesToShow + this.counter;
                        return this.slideWidth * this.counter;
                    }
                case "Prev":
                    this.counter =
                            this.counter < this.slidesToShow
                                ? this.counter - this.counter
                                : this.counter - this.slidesToShow;
                    return this.slideWidth * this.counter;
                }
            };

            TweenMax.to(".cards", 1.5, {
                x: -width() + "px",
                ease: Power1.easeOut
            });
        }
    },
};
