//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"

export default {
    name: "LocationBoxComponent",
    mixins: [GlobalMixin],
    head() {
        return {
            script: [
                {
                    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCcOR7HXBnLJljacZqbL4IIR_nbCsfYrSA&callback=initMap',
                    defer: true
                }
            ]
        }
    },
    computed: {
        address() {
            return this.getStaticText('corporate office');
        },
        sales() {
            return this.getStaticText('sales office');
        },
        phone() {
            return this.strip(this.getStaticText('phone'));
        },
        fax() {
            return this.strip(this.getStaticText('fax'));
        },
        general() {
            return this.strip(this.getStaticText('email general inquiry'));
        },
        marina() {
            return this.strip(this.getStaticText('email marina inquiry'));
        },
        rental() {
            return this.strip(this.getStaticText('email rental inquiry'));
        },
        estate() {
            return this.strip(this.getStaticText('email real estate inquiry'));
        }
    },
    mounted() {
        window.initMap = function () {
            let style = new google.maps.StyledMapType([
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ], {name: 'Styled Map'})

            let map = new google.maps.Map(document.getElementById("map"), {
                center: {lat: 42.3863874, lng: 18.6612858},
                zoom: 13,
                disableDefaultUI: true,
                scrollWheel: false,
                navigationControl: false,
                mapTypeControl: false,
                draggable: false
            })

            map.mapTypes.set('styled_map', style)
            map.setMapTypeId('styled_map')

            new google.maps.Marker({
                position: new google.maps.LatLng(42.3863874, 18.6612858),
                map: map,
                title: 'Lustica Bay',
                icon: { url: '/assets/img/marker.svg' }
            })
        }
    }
}
