//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {ValidationProvider, ValidationObserver, extend, localize} from 'vee-validate'
import {CrudHelper} from "~/vold-core/functions/Crudhelper"
import {startCase} from 'lodash';
import {GlobalMixin} from "~/mixin/GlobalMixin"
import StaticText from "~/directives/StaticText";

import en from 'vee-validate/dist/locale/en.json'
import mne from 'vee-validate/dist/locale/sr_Latin.json'
import ru from 'vee-validate/dist/locale/ru.json'

export default {
    name: "MarinaFormComponent",
    components: {
        ValidationProvider, ValidationObserver
    },
    directives: {StaticText},
    mixins: [GlobalMixin],
    data: function () {
        return {
            privacy: null,
            form: {
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                country: null,
                message: null
            }
        };
    },
    computed: {
        readPrivacyText() {
            return this.escapedStaticText('form - read privacy policy')
        },
        collectText() {
            const text = this.getStaticText('form - collect')

            if (text != null) {
                return text.replace(/<(\/|)p>/g, '').replace(/<b>(.*?)<\/b>/,
                    `<a class="btn collect__link" href="${this.renderLink(this.getCurrentLanguage + '/privacy-policy')}" target="_blank" rel="noopener noreferrer">$1</a>`)
            }

            return null
        },
        sendText() {
            return this.escapedStaticText('command - send')
        }
    },
    created() {
        const messages = {en, mne, ru}

        if (Object.hasOwnProperty.call(messages, this.getCurrentLanguage)) {
            localize(this.getCurrentLanguage, messages[this.getCurrentLanguage])
        }
    },
    mounted() {
        if (window.rules == null) {
            window.rules = require('vee-validate/dist/rules')
        }

        extend('required', window.rules.required)
        extend('email', window.rules.email)
        extend('nosymbol', this.nosymbol)
    },
    methods: {
        async onSubmit() {
            let data = [];

            for (let key of Object.keys(this.form)) {
                data.push({
                    key: startCase(key),
                    value: this.form[key],
                });
            }

            if (window.location.hostname.match(/lusticabay\.com$/) != null) {
                await CrudHelper.POST('contact-us', {
                    data,
                    type: 'contact-us',
                    template: 'contact us lustica',
                    language: 'en',
                    autoRender: true,
                    recipients: [
                        {
                            name: 'marina@lusticadevelopment.com',
                            email: 'marina@lusticadevelopment.com'
                        }
                    ]
                })
            }

            if (this.getOptions?.customFieldValues?.after_submit_link) {
                await this.$router.push(this.getOptions.customFieldValues.after_submit_link)
            } else {
                if (this.getCurrentLanguage === 'mne') {
                    await this.$router.push('/' + this.getCurrentLanguage + '/hvala')
                } else if (this.getCurrentLanguage === 'ru') {
                    await this.$router.push('/' + this.getCurrentLanguage + '/spasibo')
                } else {
                    await this.$router.push('/' + this.getCurrentLanguage + '/thank-you')
                }
            }

        }
    }
}
