import { render, staticRenderFns } from "./ImgTxtRightComponent.vue?vue&type=template&id=4886de64&scoped=true&"
import script from "./ImgTxtRightComponent.vue?vue&type=script&lang=js&"
export * from "./ImgTxtRightComponent.vue?vue&type=script&lang=js&"
import style0 from "./ImgTxtRightComponent.vue?vue&type=style&index=0&id=4886de64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4886de64",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Swap: require('/var/www/frontend-lustica-nuxt/components/partials/Swap/Swap.vue').default,ColorPicker: require('/var/www/frontend-lustica-nuxt/components/partials/ColorPicker/ColorPicker.vue').default})
