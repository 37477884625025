//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "~/mixin/GlobalMixin"
import ColorPicker from "@/components/partials/ColorPicker/ColorPicker"
import HasBackgroundColor from "@/mixin/utilities/HasBackgroundColor"
import DisposableSingleImage from "@/components/partials/DisposableSingleImage/DisposableSingleImage"
import HasDarkBox from "@/mixin/utilities/HasDarkBox"

export default {
    name: "BoxLeftComponent",
    components: {DisposableSingleImage, ColorPicker},
    mixins: [GlobalMixin, HasBackgroundColor, HasDarkBox],
    data() {
        return {
            logo: null,
        }
    }
}
