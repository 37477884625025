//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "~/mixin/GlobalMixin"
import ColorPicker from "@/components/partials/ColorPicker/ColorPicker"
import HasBackgroundColor from "@/mixin/utilities/HasBackgroundColor"
import Swap from "@/components/partials/Swap/Swap"
import Swappable from "@/mixin/utilities/Swappable"

export default {
    name: "TwoImagesComponent",
    components: {Swap, ColorPicker},
    mixins: [GlobalMixin, HasBackgroundColor, Swappable]
}
