//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"
import qSlide from "@/plugins/slider"
import {TweenMax, Expo} from "gsap"
import LinkEditor2 from "@/directives/LinkEditorPlugin"
import {mapActions} from "vuex"

export default {
    name: "BoxRightTabComponent",
    directives: {LinkEditor2},
    mixins: [GlobalMixin],
    props: {
        titlePlaceholder: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            tab: 0,
            qSlide: null,
        }
    },
    watch: {
        totalImages() {
            this.$nextTick(() => this.qSlide = new qSlide(this.$refs.q_slide))
        },
        tab(val) {
            TweenMax.staggerFrom(this.$refs.section.querySelectorAll('.box__text .columns *'),
                .5, {
                    y: 20,
                    alpha: 0,
                    ease: Expo.easeOut
                }, .05)

            TweenMax.from(this.$refs.section.querySelector('.btn'), .5, {
                delay: .34,
                y: 20,
                alpha: 0,
                ease: Expo.easeOut
            })


            for (let i = 0; i < this.getDataModel.images[val].info.length; i++) {
                const info = this.getDataModel.images[val].info[i]
                this.$refs.label[i].innerHTML = this.titlePlaceholder != null ? this.titlePlaceholder[i] : this.__(info.title)
                this.$refs.value[i].innerHTML = this.__(info.value)
            }

            this.qSlide.q_slideSwitch(this.$refs.q_slide, val, false, 'left')

            let span
            if ((span = this.$refs.title.querySelector('span')) != null) {
                this.$refs.title.querySelector('span').innerHTML =
                    this.__(this.getDataModel.images[val].title)

                TweenMax.from(span, .5, {
                    yPercent: 100,
                    ease: Expo.easeOut
                })
            } else {
                this.$refs.title.innerHTML = this.__(this.getDataModel.images[val].title)
            }
        }
    },
    methods: mapActions({
        enlarge: 'Lightbox/show'
    }),
    mounted() {
        window.addEventListener('click', (e) => {
            if (this.$refs.menu != null && this.$refs.menu.checked) {
                if (e.target.id !== 'menu') {
                    this.$refs.menu.checked = false
                }
            }
        })

        this.qSlide = new qSlide(this.$refs.q_slide)
    },
}
