//
//
//
//

import CarouselComponent from "~/components/tpl/banners/CarouselComponent/CarouselComponent";
import {GlobalMixin} from "~/mixin/GlobalMixin";

export default {
    name: "CarouselWideComponent",
    components: {CarouselComponent},
    mixins: [GlobalMixin]
}
