//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"
import DisposableSingleImage from "@/components/partials/DisposableSingleImage/DisposableSingleImage"

export default {
    name: "TextIntroComponent",
    components: {DisposableSingleImage},
    mixins: [GlobalMixin],
    computed: {
        buttons() {
            if (this.getDataModel.buttons != null) {
                return this.getDataModel.buttons.filter((button) => {
                    return this.isCurrentUserCanEdit || this.__(button.title) !== ''
                })
            }

            return []
        }
    },
    methods: {
        addButton() {
            this.getDataModel.buttons.push({
                title: 'Discover',
                url: '#',
                external: false
            })
        },
        removeButton(index) {
            this.getDataModel.buttons = this.getDataModel.buttons.filter((btn, i) => {
                return index !== i;
            });
        }
    }
}
