import {renderMultipleLanguageValue} from "../../vold-core/functions/helpers"

export const PostMixin = {
    data() {
        return {
            posts: []
        }
    },

    mounted() {
        this.getPosts();
    },

    methods: {

        getPosts() {
            this.posts = this.getListingPost
        },

        getPostListByType(type) {
            return this.posts.filter((post) => {
                return post.type == type;
            })
        },

        filterPostListByTitle(posts, title) {
            return posts.filter(post => {
                return renderMultipleLanguageValue(post.title).toLowerCase().includes(title.toLowerCase())
            })
        },

        getSinglePost() {
            return this.$store.getters.getCurrentPostDetail
        },

        getSinglePostData() {
            let single = this.$store.getters.getCurrentPostDetail || false
            if (single) {
                single = single.data
            }

            return single
        }
    }
}
