//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {TweenMax, Expo} from 'gsap'
import {GlobalMixin} from "@/mixin/GlobalMixin"
import qSlide from "@/plugins/slider"

export default {
    name: "SliderProgressComponent",
    mixins: [GlobalMixin],
    data() {
        return {
            progress: 0,
            circumference: 34 * 2 * Math.PI,
            page: 0,
            qSlide: null
        }
    },
    computed: {
        strokeDashOffset() {
            return this.circumference - this.progress / 100 * this.circumference
        },
        totalPage() {
            return this.getDataModel.sliders.length
        },
        currentPage() {
            return this.page + 1;
        },
        slider() {
            return this.getDataModel.sliders[this.page];
        },
        previousText() {
            const text = this.getStaticText('command - previous')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        },
        nextText() {
            const text = this.getStaticText('command - next')

            if (text != null) {
                return text.replace(/<.*?>/g, '')
            }

            return null
        }
    },
    watch: {
        page(val) {
            this.$refs.title.textContent = this.__(this.slider.title);
            this.$refs.description.textContent = this.__(this.slider.description);

            TweenMax.to(this.$data, 0.75, {
                progress: this.currentPage / this.totalPage * 100
            });

            this.qSlide.q_slideSwitch(this.$refs.q_slide, val, false);

            TweenMax.staggerFrom(this.$refs.section.querySelectorAll('.slider-content *'), 0.5, {
                y: 20,
                alpha: 0,
                ease: Expo.easeOut
            }, .05);
        },
        totalPage() {
            this.$nextTick(() => this.qSlide = new qSlide(this.$refs.q_slide))

            TweenMax.to(this.$data, 0.65, {
                progress: this.currentPage / this.totalPage * 100
            });
        }
    },
    mounted() {
        this.progress = this.currentPage / this.totalPage * 100;

        this.qSlide = new qSlide(this.$refs.q_slide)

        TweenMax.to(this.$refs.progress, {
            drawSVG: '50%'
        })
    },
    methods: {
        next() {
            if (this.page < this.totalPage - 1) {
                this.page++;
            }
        },

        previous() {
            if (this.page > 0) {
                this.page--;
            }
        }
    }
}
