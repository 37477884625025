//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import qSlide from "~/plugins/slider"
import {GlobalMixin} from "@/mixin/GlobalMixin"
import LinkEditor2 from "@/directives/LinkEditorPlugin"
import {TweenMax} from 'gsap'

export default {
    name: "HeroVideoSliderComponent",
    directives: {LinkEditor2},
    mixins: [GlobalMixin],
    mounted() {
        new qSlide(this.$refs.q_slide)
        const revealItems = this.$refs.headerAnimate.querySelectorAll('.reveal_item')
        TweenMax.set(revealItems, {
            skewY: 5,
            yPercent: 115,
        })
    }
}
