//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Carousel from "@/plugins/carousel"
import {GlobalMixin} from "@/mixin/GlobalMixin"
import ColorPicker from "@/components/partials/ColorPicker/ColorPicker"
import HasBackgroundColor from "@/mixin/utilities/HasBackgroundColor"
import ImageGalleryEditor2 from "@/directives/ButtonGalleryEditorPlugin"

export default {
    name: "DiscoverComponent",
    components: {ColorPicker},
    directives: {ImageGalleryEditor2},
    mixins: [GlobalMixin, HasBackgroundColor],
    data() {
        return {
            planeNumber: 0,
            totalPage: 0,
            carousel: null
        }
    },
    computed: {
        sliders() {
            return this.getDataModel.sliders;
        }
    },
    watch: {
        sliders() {
            const options = {
                duration: 500,
                dragSpeed: 1.5,
                element: this.$refs.main_carousel
            }

            this.carousel.destroy();

            setTimeout(function () {
                this.carousel = new Carousel(options)
                this.planeNumber = this.carousel.planeNumber;
            }, 500);
        }
    },
    mounted() {
        const options = {
            duration: 500,
            dragSpeed: 1.5,
            element: this.$refs.main_carousel
        }

        this.carousel = new Carousel(options)

        this.planeNumber = this.carousel.planeNumber;
    }
}
