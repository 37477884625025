//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GlobalMixin } from "~/mixin/GlobalMixin";
import {CursorMixin} from "~/mixin/animations"

export default {
    name: "ThreeCardsComponent",
    mixins: [GlobalMixin, CursorMixin]
}
