//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {GlobalMixin} from "@/mixin/GlobalMixin"
import {VoldPMAction} from "@/vold-core/mixin/VoldPostMessageEditorMixin"
import {v1 as uuidv4} from 'uuid'

export default {
    name: "DisposableSingleImage",
    mixins: [GlobalMixin, VoldPMAction],
    props: {
        value: {
            required: false,
            type: Object
        }
    },
    data() {
        return {
            logo: null,
            buttonId: uuidv4()
        }
    },
    watch: {
        logo(val) {
            this.$emit('input', val)
        }
    },
    mounted() {
        this.logo = this.value;

        if (this.isCurrentUserCanEdit) {
            this.$root.$on('PM', event => {
                if (!event) return
                const {message} = event
                if (event.key === 'SELECTED_FILE') {
                    if (message.to === this.buttonId) {
                        this.logo = message.file
                    }
                }
            })
        }
    },
    methods: {
        addLogo() {
            this.sendMessageToParentWindow('ACCESS_FILE_MANAGER', this.buttonId)
            if (this.$parent) this.$parent.$emit('callFileManagerId', this.buttonId)
        },
        removeLogo() {
            this.logo = null
        }
    }
}
