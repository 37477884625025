import Cursor from "~/plugins/Cursor"
import {mapState} from 'vuex'

const CursorMixin = {
    data() {
        return {
            cursor: null
        }
    },
    computed: mapState({
        loadingCompleted: state => state.SmoothScroll.loadingCompleted
    }),
    watch: {
        loadingCompleted() {
            this.initCursor()
        }
    },
    mounted() {
        this.$router.beforeEach((to, from, next) => {
            if (this.cursor != null) {
                this.cursor.cancel()
            }

            document.body.classList.remove('custom_cursor')
            next()
        })
    },
    methods: {
        initCursor() {
            document.body.classList.add('custom_cursor')
            this.cursor = new Cursor(this.$store.getters.getCurrentPageData.static_texts,
                this.$store.getters.getCurrentLanguage)
            this.$store.commit('setCursorAnimation', this.cursor)
        }
    },
}

export default CursorMixin
