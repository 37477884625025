import {TweenMax, Power0, Linear, Expo} from "gsap"
import {mapState} from 'vuex'

const SmoothScrollMixin = {
    data() {
        return {
            smoothScroll: null,
            sections: null,
            sectionsBg: null,
            headerBg: null,

            prevScroll: 0,
            curScroll: 0,
            direction: 0,
            prevDirection: 0
        }
    },
    watch: {
        loadingCompleted(val) {
            if (val) this.init()
        },
        isCurrentUserCanEdit(val) {
            if (val) {
                this.smoothScroll.destroy()
                this.smoothScroll = null;
            }
        }
    },
    computed: mapState({
        loadingCompleted: state => state.SmoothScroll.loadingCompleted,
        isCurrentUserCanEdit: state => state.isCurrentUserCanEditContent,
        loadingShown: state => state.Animation.isLoadingShown,
    }),
    methods: {
        init() {

            setTimeout(() => {
                const headerBg = document.getElementById('headerBg')
                if (headerBg != null) {
                }

                this.sections = document.querySelectorAll('.section')
                this.sectionsBg = document.querySelector('.main_background')
                this.headerBg = document.querySelector('#headerBg')
                this.smoothScrollInit()

                this.animated = document.querySelectorAll('.header_animate')

                this.animateHeader()

                if (this.$store.getters.getCurrentUserCanEditContent) {
                    return;
                }

                this.smoothScroll.on('scroll', (t) => {
                    this.bgChange()
                    this.headerScroll(t)
                    this.hideShowHeader(t)
                })

                this.smoothScroll.on('call', function (value, way, obj) {
                    if (value === 'revMask') {
                        const reveal_masks = obj.el.querySelectorAll('.text_cover')

                        if (reveal_masks && obj.el.classList.contains('is-inview')) {
                            const continueAnim = () => {
                                TweenMax.to(reveal_masks, .5, {
                                    webkitMaskImage: "linear-gradient(170deg, rgb(0, 0, 0) ".concat(100, "%, rgba(255, 255, 255, 0) ").concat(100, "%)"),
                                    ease: Linear.easeNone
                                })
                            }

                            TweenMax.staggerTo(reveal_masks, 1.4, {
                                webkitMaskSize: "cover",
                                webkitMaskImage: "linear-gradient(170deg, rgb(0, 0, 0) ".concat(70, "%, rgba(255, 255, 255, 0) ").concat(100, "%)"),
                                ease: Power0.easeOut,
                                delay: .3,
                                onComplete: continueAnim
                            }, .1)
                        }
                    }
                })

                this.triggerResize()
            }, this.loadingShown ? 10 : 500)
        },
        triggerResize() {
            const ev = document.createEvent('HTMLEvents')
            ev.initEvent('resize', true, false)
            window.dispatchEvent(ev)
        },

        createSmoothScroll(element) {
            if (this.$store.getters.getCurrentUserCanEditContent) return

            const locoScroll = require('locomotive-scroll').default
            const scroll = new locoScroll({
                el: element,
                smooth: true,
            })

            let reveals = document.querySelectorAll('.reveal')

            for (let reveal of reveals) {
                reveal.classList.add('reveal__on')
            }

            return scroll
        },

        smoothScrollInit() {

            const smoothScroll = this.$store.getters.getSmoothScroll

            if (!smoothScroll) {
                const scroll = this.createSmoothScroll(document.querySelector('#mainScroll'))
                this.smoothScroll = scroll
                this.$store.commit('setSmoothScroll', scroll)
            } else {
                smoothScroll.destroy()
                const scroll = this.createSmoothScroll(document.querySelector('#mainScroll'))
                this.smoothScroll = scroll
                this.$store.commit('setSmoothScroll', scroll)
            }
        },

        bgChange() {
            this.sections.forEach((section) => {
                let offsetTop = section.getBoundingClientRect().top + document.body.scrollTop
                let offsetBtm = section.getBoundingClientRect().bottom + document.body.scrollTop
                const bgColor = section.getAttribute('data-bg')

                if (offsetTop < (window.innerHeight * .5) && offsetBtm > (window.innerHeight * .5)) {
                    if (bgColor) {
                        if (this.sectionsBg == null || this.headerBg == null) return;

                        if (!this.sectionsBg.classList.contains(bgColor)) {
                            this.sectionsBg.classList.remove('cream', 'grey', 'green', 'gold', 'white', 'bluish')
                            this.headerBg.classList.remove('cream', 'grey', 'green', 'gold', 'white', 'bluish')
                            this.sectionsBg.classList.add(bgColor)
                            this.headerBg.classList.add(bgColor)
                        }
                    } else {
                        this.sectionsBg.classList.remove('cream', 'grey', 'green', 'gold', 'white', 'bluish')
                        this.headerBg.classList.remove('cream', 'grey', 'green', 'gold', 'white', 'bluish')
                    }
                }
            })
        },

        headerScroll(t) {
            const header = document.querySelector('.header-wrap')
            const scrollPos = t.scroll.y
            if (scrollPos > 100 && this.loadingCompleted && this.loadingShown) {
                header.classList.add('scrolled')
            } else if (scrollPos < 100) {
                header.classList.remove('scrolled')
                this.$store.dispatch('hideLanguageSelector')
            }
        },

        toggleHeader(direction, curScroll) {
            const header = document.querySelector('.header-wrap')
            if (direction === 2 && curScroll > window.innerHeight) {
                this.prevDirection = direction
                this.$store.dispatch('hideLanguageSelector')
            } else if (direction === 1) {
                this.prevDirection = direction
            }
        },

        hideShowHeader(t) {
            this.curScroll = t.scroll.y
            if (this.curScroll > this.prevScroll) {
                this.direction = 2
            } else if (this.curScroll < this.prevScroll) {
                this.direction = 1
            }

            if (this.direction !== this.prevDirection) {
                this.toggleHeader(this.direction, this.curScroll)
            }
            this.prevScroll = this.curScroll
        },

        animateHeader() {
            /**
             * Header Background Animation
             * @see ".animate-bg" css class for initial style set
             * timeout for parallax adjustment
             */
            const animatebBg = document.querySelector('.animate-bg')
            if(animatebBg) {
                setTimeout(() => {
                    TweenMax.fromTo(animatebBg, 3, {
                        scale: 1.1,
                    },{
                        scale: 1,
                        opacity: 1,
                        ease: Expo.easeOut,
                        onComplete:() => {
                            this.triggerResize()
                        }
                    })
                }, 100);
            }

            if (this.animated) {
                this.animated.forEach(headerWrap => {

                    // image/video opacity
                    headerWrap.classList.add('active')

                    const revealItems = headerWrap.querySelectorAll('.reveal_item');
                    const video = headerWrap.querySelector('video');

                    // text stagger
                    if (revealItems) {
                        TweenMax.staggerFromTo(
                            revealItems, 1, {
                                skewY: 5,
                                yPercent: 115,
                            }, {
                                yPercent: 0,
                                skewY: 0,
                                ease: Expo.easeOut,
                            }, .12
                        );
                    }

                    // play video
                    if (video) {
                        video.play()
                    }
                })
            }

        }

    },
}

export default SmoothScrollMixin
