import { render, staticRenderFns } from "./BoxRightComponent.vue?vue&type=template&id=612c8a2e&scoped=true&"
import script from "./BoxRightComponent.vue?vue&type=script&lang=js&"
export * from "./BoxRightComponent.vue?vue&type=script&lang=js&"
import style0 from "./BoxRightComponent.vue?vue&type=style&index=0&id=612c8a2e&lang=scss&scoped=true&"
import style1 from "./BoxRightComponent.vue?vue&type=style&index=1&lang=scss&v-if=isCurrentUserCanEdit&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "612c8a2e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DisposableSingleImage: require('/var/www/frontend-lustica-nuxt/components/partials/DisposableSingleImage/DisposableSingleImage.vue').default,ColorPicker: require('/var/www/frontend-lustica-nuxt/components/partials/ColorPicker/ColorPicker.vue').default})
