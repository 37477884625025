import {eq, triggerResize, indexInParent} from "~/plugins/helper";
import {TweenMax, Expo} from 'gsap';

export default function (q_slide) {
    q_slide.classList.add('q_slide');

    // global vars

    var slides = q_slide.querySelectorAll('.slide');
    var timeout = 0;


    // autoplay function

    function autoPlay() {
        if (q_slide.getAttribute('autoplay') != null) {
            var q_slideDuration = q_slide.getAttribute('autoplay') || 4000;
            timeout = setTimeout(function () {
                q_slideNext(q_slide, false, true);
            }, q_slideDuration);
        }
    }


    // parallax init

    var q_slideFriction = 1; // no parallax
    if (q_slide.getAttribute('parallax') != null) {
        q_slideFriction = q_slide.getAttribute('parallax') || 0.25;
    }

    // opacity init

    if (q_slide.getAttribute('opacity') != null) {
        var slideOpacity = q_slide.getAttribute('opacity') || 0.6;
    }


    // switch slide function

    function q_slideSwitch(q_slide, index, auto, direction) {


        if (q_slide.getAttribute('wait') === 'true') return;

        var captions = q_slide.querySelectorAll('.slide-content');

        var activeSlide = q_slide.querySelector('.q_current');
        var activeSlideImage = activeSlide.querySelector('.image-container');
        var newSlide = eq.call(slides, index);
        index = indexInParent(newSlide);
        var newSlideImage = newSlide.querySelector('.image-container');
        var newSlideContent = newSlide.querySelector('.slide-content') || captions[index];

        if (newSlideContent) {
            var newSlideElements = newSlideContent.querySelectorAll('.q_split_wrap');
        }

        if (newSlide === activeSlide) return;

        newSlide.classList.add('is-new');

        clearTimeout(timeout);

        q_slide.setAttribute('wait', 'true');

        if (indexInParent(newSlide) > indexInParent(activeSlide)) {
            // next slide
            var newSlideRight = 0;
            var newSlideLeft = 'auto';
            var newSlideImageLeft = -q_slide.clientWidth * (1 - q_slideFriction) + 'px';
            var newSlideContentLeft = 'auto';
            var newSlideContentRight = 0;
            var activeSlideImageLeft = -q_slide.clientWidth * q_slideFriction + 'px';

            var slideAnim = 'stagLeft';
            var fadeAnim = 'fadeLeft';
        } else {
            // prev slide
            newSlideRight = '';
            newSlideLeft = 0;
            newSlideImageLeft = -q_slide.clientWidth * q_slideFriction + 'px';
            newSlideContentLeft = 0;
            newSlideContentRight = 'auto';
            activeSlideImageLeft = q_slide.clientWidth * q_slideFriction + 'px';

            slideAnim = 'stagRight';
            fadeAnim = 'fadeRight';

        }

        if (direction === 'left') {
            newSlideRight = 0;
            newSlideLeft = 'auto';
            newSlideImageLeft = -q_slide.clientWidth * (1 - q_slideFriction) + 'px';
            newSlideContentLeft = 'auto';
            newSlideContentRight = 0;
            activeSlideImageLeft = -q_slide.clientWidth * q_slideFriction + 'px';

            slideAnim = 'stagLeft';
            fadeAnim = 'fadeLeft';
        } else if (direction === 'right') {
            newSlideRight = '';
            newSlideLeft = 0;
            newSlideImageLeft = -q_slide.clientWidth * q_slideFriction + 'px';
            newSlideContentLeft = 0;
            newSlideContentRight = 'auto';
            activeSlideImageLeft = q_slide.clientWidth * q_slideFriction + 'px';

            slideAnim = 'stagRight';
            fadeAnim = 'fadeRight';
        }

        newSlide.style.display = 'block';
        newSlide.style.width = 0;
        newSlide.style.right = newSlideRight;
        newSlide.style.left = newSlideLeft;
        newSlide.style.zIndex = 2;

        newSlideImage.style.width = q_slide.clientWidth + 'px';
        activeSlideImage.style.transform = 'translateX(0)';

        TweenMax.set(newSlideImage, { x: newSlideImageLeft });


        var img = newSlide.querySelector('img.image')
        var video = newSlide.querySelector('video')

        // kalo backgroundnya image
        if (img) {
            TweenMax.set(img, { scale: 1.4, rotation: 5 });
        }



        if (slideOpacity) {
            newSlideImage.style.opacity = slideOpacity;
        }



        if (newSlideContent) {
            newSlideContent.style.width = q_slide.clientWidth + 'px';
            newSlideContent.style.right = newSlideContentRight;
            newSlideContent.style.left = newSlideContentLeft;
        }


        TweenMax.to(activeSlideImage, 1.2, {
            x: activeSlideImageLeft,
            opacity: slideOpacity,
            ease: Expo.easeInOut
        });

        var old_video = activeSlideImage.querySelector('video')

        if (old_video) {
            setTimeout(function () {
                old_video.pause()
            }, 1000);
        }

        TweenMax.to(newSlide, 1.2, {
            width: q_slide.clientWidth,
            ease: Expo.easeInOut
        });

        img = newSlide.querySelector('img')
        video = newSlide.querySelector('video')

        // kalo backgroundnya image vs video
        if (video) {
            setTimeout(function () {
                video.play()
            }, 10);
        } else if (img) {
            TweenMax.to(img, 3, {
                scale: 1,
                rotation: 0,
                ease: Expo.easeOut
            });
        }






        TweenMax.to(newSlideImage, 1.2, {
            x: 0,
            opacity: 1,

            ease: Expo.easeInOut,
            onComplete: function () {
                // switch active class
                newSlide.classList.add('q_current');
                newSlide.classList.remove('is-new');
                activeSlide.classList.remove('q_current');
                newSlide.removeAttribute('style');
                // reset all styles
                newSlideImage.removeAttribute('style');
                if (newSlideContent) {
                    newSlideContent.removeAttribute('style');
                }
                activeSlideImage.removeAttribute('style');
                q_slide.setAttribute('wait', 'false');

                if (auto) {
                    autoPlay()
                }
            }
        });

        // caption animations

        // current slide animation

        if (fadeAnim === 'fadeLeft') {
            TweenMax.staggerFromTo(
                activeSlide.querySelectorAll('.q_split_wrap'), .6,
                { alpha: 1, x: 0 },
                { alpha: 0, x: -50, ease: Expo.easeIn, delay: 0 }, 0.05
            );
        }

        if (fadeAnim === 'fadeRight') {
            TweenMax.staggerFromTo(
                activeSlide.querySelectorAll('.q_split_wrap'), .6,
                { alpha: 1, x: 0 },
                { alpha: 0, x: 50, ease: Expo.easeIn, delay: 0 }, 0.05
            );
        }

        // next slide animation

        if (slideAnim === 'stagLeft') {
            TweenMax.staggerFromTo(
                newSlideElements, 1.5,
                { alpha: 0, x: 50 },
                { alpha: 1, x: 0, ease: Expo.easeOut, delay: .5 }, 0.1
            );
        }

        if (slideAnim === 'stagRight') {
            TweenMax.staggerFromTo(
                newSlideElements, 1.5,
                { alpha: 0, x: -50 },
                { alpha: 1, x: 0, ease: Expo.easeOut, delay: .5 }, 0.1
            );
        }

    }

    if (this != null) {
        this.q_slideSwitch = q_slideSwitch;
    }

    // next/prev slide switch calls

    function q_slideNext(q_slide, previous, auto) {

        var activeSlide = q_slide.querySelector('.q_current');
        var newSlide = null;
        if (previous) {
            newSlide = activeSlide.previousElementSibling;
            if (!newSlide) {
                newSlide = slides[slides.length - 1];
            }
        } else {
            newSlide = activeSlide.nextElementSibling;
            if (!newSlide)
                newSlide = slides[0];
        }

        q_slideSwitch(q_slide, indexInParent(newSlide), auto);

        triggerResize();

    }




    for (var i = slides.length - 1; i >= 0; i--) {
        var slide = slides[i];
        slide.classList.add('is-loaded');
    }

    // arrows click event

    var arrows = q_slide.querySelector('.arrows');

    if (arrows) {
        var next = arrows.querySelector('.next');
        var prev = arrows.querySelector('.prev');

        const nextEvent = () =>{
            q_slideNext(q_slide, false);
        };

        const prevEvent = () =>{
            q_slideNext(q_slide, true);
        };

        next.addEventListener('click', nextEvent);
        prev.addEventListener('click', prevEvent);

        if (this != null) {
            this.destroy = () => {
                next.removeEventListener('click', nextEvent);
                prev.removeEventListener('click', prevEvent);
            };
        }
    }

    autoPlay(); // autoplay init


}
